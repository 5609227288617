<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row class="mt-n5">
                <v-col cols="3">
                    <v-select
                        class="text-h6"
                        single-line
                        :items="selSearchTypes"
                        v-model="search.search_type"
                        label="검색 형식을 선택해 주세요"
                        item-text="txt"
                        item-value="val"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        class="text-h6"
                        v-model="search.search_text"
                        ref="searchText"
                        @keyup.enter="doSearch(1)"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                        class="mx-2"
                        fab
                        color="#F4F6F8"
                        elevation="0"
                        @click="doSearch(1)"
                        style="float:left;"
                    >
                        <v-icon color="#A5ACB4">mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card flat>
                <v-card-text class="pl-0 pr-0 pb-0">
                    <v-simple-table class="border-table">
                        <thead>
                        <tr style="background-color:#F4F6F8; height:58px; color:#333333;">
                            <th style="width: 14%" class="text-center text-h6">번호</th>
                            <th style="width: 10%" class="text-center text-h6">이름</th>
                            <th class="text-center text-h6">설명</th>
                            <th style="width: 10%" class="text-center text-h6">등록자</th>
                            <th style="width: 20%" class="text-center text-h6">차단일시</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="blockMdn in blockMdnList">
                            <tr style="height:58px;" :key="'block_mdn_seq_' + blockMdn.block_seqno">
                                <td class="text-center text-subtitle-1">{{ blockMdn.block_mdn | phoneNum }}</td>
                                <td class="text-center text-subtitle-1">{{ blockMdn.block_name }}</td>
                                <td class="text-left text-subtitle-1">{{ blockMdn.block_desc }}</td>
                                <td class="text-center text-subtitle-1">{{ blockMdn.reg_user_name }}</td>
                                <td class="text-center text-subtitle-1">{{ blockMdn.reg_dt | dateSec }}</td>
                            </tr>
                        </template>
                        <tr v-if="!blockMdnList || blockMdnList.length == 0" style="height: 58px;">
                            <td class="text-center" colspan="6" style="font: normal normal 600 16px/25px Apple SD Gothic Neo;">수신 차단 목록이 없습니다.</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            <v-row class="mt-4">
                <v-col cols="12" class="text-right">
                    <tile-paging :pageNo="search.page_no" :totalPageCount="totalPageCount" @goPage="doSearch"></tile-paging>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'svc_block_mdn_list',
    components: {
        'tilePaging': () => import('@/components/common/tile_paging.vue'),
    },
    data: () => ({
        search: {
            search_text: '',
            search_type: 'SVC_MDN',
            page_no: 1,
            page_size: 25,
            block_yn: 'Y'
        },
        selSearchTypes: [
            {'txt': '검색유형: 번호', 'val': 'SVC_MDN'},
            {'txt': '검색유형: 이름', 'val': 'BLOCK_NAME'},
            {'txt': '검색유형: 설명', 'val': 'BLOCK_DESC'}
        ],
        selBlockYns: [
            {'txt': '차단여부: 전체', 'val': ''},
            {'txt': '차단여부: 차단', 'val': 'Y'},
            {'txt': '차단여부: 해제', 'val': 'N'}
        ],
        windowSize: {
            x: 0,
            y: 0
        }
    }),
    computed: {
        ...mapState('svc_block_mdn', {
            curSearchParam: state => state.searchParam,
            blockMdnList: state => state.blockMdnList,
            totalCount: state => state.totalCount
        }),
        totalPageCount() {
            if (this.totalCount == 0) {
                return 0
            }
            let pageSize = (this.search && this.search.page_size) ? this.search.page_size : 25
            let cnt = Math.floor(this.totalCount / pageSize) + (((this.totalCount % pageSize) > 0) ? 1 : 0)
            return cnt
        },
    },
    created() {
        this.doSearch()
    },
    methods: {
        doSearch: function (pageNo) {

            if (pageNo) {
                this.search.page_no = pageNo
            }

            if (this.search.search_type == 'SVC_MDN' && this.search.search_text.length != 0) {
                let replaceText = this.search.search_text.replaceAll(/[^0-9]/g, '')
                this.search.search_text = replaceText
                this.$refs.searchText = replaceText

                if (replaceText.length == 0) {
                    this.$store.dispatch('openAlert', {
                        'message': '서비스 번호를 입력해 주세요',
                        'sub_message': '서비스 번호는 숫자만 입력가능합니다.'
                    })
                    return
                }
            }

            this.$store.dispatch('svc_block_mdn/getList', {
                param: {
                    search_type: this.search.search_type,
                    search_text: this.search.search_text,
                    block_yn: this.search.block_yn,
                    page_no: this.search.page_no,
                    page_size: this.search.page_size
                },
                scb: () => {

                }, fcb: err => {
                    if (this.$noSessionProc(err)) {
                        console.log('authentication check failed')
                    }
                }
            })
        }
    }
}
</script>